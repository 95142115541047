import { Modals } from '../../../shared/contexts/ModalContext';
import {
  WritableValueWithCallbacks,
  createWritableValueWithCallbacks,
} from '../../../shared/lib/Callbacks';
import { CancelablePromise } from '../../../shared/lib/CancelablePromise';
import { showUploadSelector } from '../../../shared/upload/selector/showUploadSelector';
import { JourneyAudioContent } from './JourneyAudioContent';
import { JourneyAudioContentList } from './JourneyAudioContentList';
import { keyMap } from './JourneyAudioContents';

/**
 * Allows the user to peruse already uploaded journey audio contents
 * and returns the one they choose.
 */
export const showJourneyAudioContentSelector = (
  modals: WritableValueWithCallbacks<Modals>
): CancelablePromise<JourneyAudioContent | undefined> => {
  return showUploadSelector({
    modals,
    content: {
      description: (
        <>
          <p>
            Choose from recently uploaded audio files below. The content is shown from most recently
            uploaded to least recently uploaded.
          </p>
          <p>
            It is usually easier to use the upload option to select a local file, as audio files are
            automatically de-duplicated: re-uploading is extremely fast and has no negative impact.
          </p>
        </>
      ),
      path: '/api/1/journeys/audio_contents/search',
      keyMap,
      itemsComponent: ({ items, onClick }) => (
        <JourneyAudioContentList items={items} onClick={onClick} />
      ),
      fetchLimit: 6,
      loadMoreReplaces: true,
      sort: createWritableValueWithCallbacks([
        { key: 'last_uploaded_at', dir: 'desc', before: null, after: null },
      ]),
    },
  });
};
