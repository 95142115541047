import { memo } from 'react';
import {
  areResizableSvgPropsEqual,
  computeResizableSvgProps,
  ResizableSvgProps,
} from '../../models/ResizableSvgProps';

/** Microphone icon / standing mic */
export const Microphone = memo((props: ResizableSvgProps) => {
  const c = computeResizableSvgProps({ ...props, natural: { width: 24, height: 24 } });
  return (
    <svg
      width={c.width}
      height={c.height}
      viewBox={c.viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.22222 6.33333C9.22222 5.58189 9.51488 4.86122 10.0358 4.32986C10.5567 3.79851 11.2633 3.5 12 3.5C12.7367 3.5 13.4433 3.79851 13.9642 4.32986C14.4851 4.86122 14.7778 5.58189 14.7778 6.33333V12.5667C14.7778 13.3181 14.4851 14.0388 13.9642 14.5701C13.4433 15.1015 12.7367 15.4 12 15.4C11.2633 15.4 10.5567 15.1015 10.0358 14.5701C9.51488 14.0388 9.22222 13.3181 9.22222 12.5667V6.33333Z"
        fill={c.color}
      />
      <path
        d="M7.55556 10.8667C7.7029 10.8667 7.84421 10.9264 7.94839 11.0326C8.05258 11.1389 8.11111 11.283 8.11111 11.4333V12.5667C8.11111 13.6187 8.52083 14.6276 9.25014 15.3715C9.97945 16.1154 10.9686 16.5333 12 16.5333C13.0314 16.5333 14.0206 16.1154 14.7499 15.3715C15.4792 14.6276 15.8889 13.6187 15.8889 12.5667V11.4333C15.8889 11.283 15.9474 11.1389 16.0516 11.0326C16.1558 10.9264 16.2971 10.8667 16.4444 10.8667C16.5918 10.8667 16.7331 10.9264 16.8373 11.0326C16.9415 11.1389 17 11.283 17 11.4333V12.5667C17.0001 13.8213 16.5469 15.0319 15.727 15.967C15.0065 16.7887 14.0474 17.3471 12.996 17.5651C12.7471 17.6167 12.5556 17.8277 12.5556 18.0819V18.8667C12.5556 19.1428 12.7794 19.3667 13.0556 19.3667H14.7778C14.9251 19.3667 15.0664 19.4264 15.1706 19.5326C15.2748 19.6389 15.3333 19.783 15.3333 19.9333C15.3333 20.0836 15.2748 20.2278 15.1706 20.334C15.0664 20.4403 14.9251 20.5 14.7778 20.5H9.22222C9.07488 20.5 8.93357 20.4403 8.82939 20.334C8.7252 20.2278 8.66667 20.0836 8.66667 19.9333C8.66667 19.783 8.7252 19.6389 8.82939 19.5326C8.93357 19.4264 9.07488 19.3667 9.22222 19.3667H10.9444C11.2206 19.3667 11.4444 19.1428 11.4444 18.8667V18.0819C11.4444 17.8277 11.2529 17.6167 11.004 17.5651C9.95261 17.3471 8.99347 16.7887 8.27302 15.967C7.45308 15.0319 6.99985 13.8213 7 12.5667V11.4333C7 11.283 7.05853 11.1389 7.16272 11.0326C7.26691 10.9264 7.40821 10.8667 7.55556 10.8667Z"
        fill={c.color}
      />
    </svg>
  );
}, areResizableSvgPropsEqual);
