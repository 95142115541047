export const EmptyHeartIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9926 8.55606C11.3779 8.49362 10.7563 8.55305 10.1669 8.73061C9.57755 8.90817 9.03315 9.20001 8.56803 9.58775C8.10291 9.9755 7.72714 10.4507 7.46438 10.9836C7.20162 11.5164 7.05757 12.0953 7.04129 12.6837C7.02501 13.2721 7.13686 13.8574 7.3698 14.4027C7.60273 14.9479 7.95171 15.4414 8.39475 15.8519L8.40182 15.8584L8.40179 15.8585L16.0101 23.0337L23.6184 15.8585C23.6416 15.8366 23.6656 15.8159 23.6902 15.7963C24.0974 15.3986 24.4193 14.9288 24.6374 14.4131C24.8677 13.8688 24.9775 13.2851 24.96 12.6985C24.9425 12.1119 24.798 11.5351 24.5356 11.0041C24.2732 10.4732 23.8985 9.99952 23.4351 9.61279C22.9716 9.22606 22.4292 8.93458 21.842 8.75661C21.2548 8.57864 20.6353 8.518 20.0223 8.57848C19.4094 8.63897 18.8161 8.81929 18.2797 9.10814C17.7433 9.397 17.2752 9.78817 16.9049 10.2572C16.6927 10.5258 16.36 10.6835 16.007 10.6825C15.654 10.6816 15.3222 10.5222 15.1116 10.2524C14.7429 9.77999 14.2754 9.38537 13.7386 9.09341C13.2018 8.80146 12.6073 8.6185 11.9926 8.55606ZM25.1167 17.4461L16.7979 25.2914C16.3616 25.7029 15.6586 25.7029 15.2223 25.2914L6.8296 17.3764C6.1668 16.7613 5.64463 16.0224 5.29587 15.206C4.94647 14.3881 4.77869 13.5102 4.80311 12.6276C4.82753 11.7449 5.04361 10.8766 5.43775 10.0774C5.83189 9.27813 6.39554 8.56526 7.09322 7.98364C7.79091 7.40203 8.6075 6.96427 9.49158 6.69793C10.3757 6.43159 11.3081 6.34245 12.2301 6.43611C13.1522 6.52977 14.0439 6.8042 14.849 7.24213C15.2678 7.46988 15.6584 7.73928 16.0147 8.04508C16.3707 7.74298 16.7603 7.47702 17.1776 7.25234C17.9822 6.81906 18.8721 6.54859 19.7915 6.45786C20.711 6.36713 21.6402 6.45809 22.5211 6.72505C23.4019 6.992 24.2154 7.42921 24.9106 8.00932C25.6059 8.58942 26.1679 9.29992 26.5615 10.0963C26.9551 10.8928 27.1718 11.758 27.1981 12.6379C27.2243 13.5177 27.0596 14.3933 26.7142 15.2097C26.3688 16.0262 25.8501 16.766 25.1906 17.3827C25.1668 17.4051 25.1421 17.4262 25.1167 17.4461Z"
      fill="#EAEAEB"
    />
  </svg>
);
