export const Sheet = () => (
  <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 18.5C5.44772 18.5 5 18.0523 5 17.5V6.5C5 5.94772 5.44772 5.5 6 5.5H16C16.5523 5.5 17 5.94772 17 6.5V17.5C17 18.0523 16.5523 18.5 16 18.5H6Z"
      stroke="#EAEAEB"
      strokeWidth="1.25"
      shapeRendering="crispEdges"
    />
    <path
      d="M11.9 15.5H7.7C7.3134 15.5 7 15.1866 7 14.8C7 14.4134 7.3134 14.1 7.7 14.1H11.9C12.2866 14.1 12.6 14.4134 12.6 14.8C12.6 15.1866 12.2866 15.5 11.9 15.5Z"
      fill="#EAEAEB"
    />
    <path
      d="M14.3 12.7H7.7C7.3134 12.7 7 12.3866 7 12C7 11.6134 7.3134 11.3 7.7 11.3H14.3C14.6866 11.3 15 11.6134 15 12C15 12.3866 14.6866 12.7 14.3 12.7Z"
      fill="#EAEAEB"
    />
    <path
      d="M14.3 9.9H7.7C7.3134 9.9 7 9.5866 7 9.2C7 8.8134 7.3134 8.5 7.7 8.5H14.3C14.6866 8.5 15 8.8134 15 9.2C15 9.5866 14.6866 9.9 14.3 9.9Z"
      fill="#EAEAEB"
    />
  </svg>
);
