const AndroidMessages = ({ width, height }: { width?: number; height?: number }) => (
  <svg width={width ?? 192} height={height ?? 192} viewBox="-40 -70 302 302">
    <circle fill="white" cx={96} cy={96} r={136} />
    <g transform="translate(0 8)">
      <path
        fill="#86A9FF"
        d="M58.639 123.429C26.222 123.429 0 95.767 0 61.714S26.222 0 58.639 0h61.008c32.417 0 58.639 27.662 58.639 61.714s-26.222 61.714-58.639 61.714H58.639z"
      />
      <path
        fill="#578CFF"
        d="M98.181 148.368V186c0 3.274-2.637 6-5.798 6a5.69 5.69 0 0 1-4.087-1.773l-57.189-58.903c-11.201-11.451-17.393-27.13-17.393-43.358 0-33.405 26.222-60.538 58.639-60.538h61.008c32.417 0 58.639 27.134 58.639 60.538s-26.222 60.538-58.639 60.538h-35.18v-.136z"
      />
      <path
        fill="#0057CC"
        d="M58.354 123.429h61.155c32.493 0 58.776-28.303 58.776-63.147 0-4.125-.398-8.105-1.056-12.089-10.697-12.799-26.283-20.763-43.587-20.763H72.49c-32.493 0-58.776 28.303-58.776 63.147 0 4.125.398 8.105 1.056 11.945 10.697 12.799 26.283 20.907 43.587 20.907h-.003z"
      />
    </g>
    <circle cx={208} cy={-16} r={54} fill="#FF0000" />
  </svg>
);
export default AndroidMessages;
