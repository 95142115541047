const HugeCheck = () => (
  <svg
    width="108"
    height="108"
    viewBox="0 0 108 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.53706"
      y="1.53706"
      width="104.926"
      height="104.926"
      rx="52.4629"
      stroke="white"
      strokeWidth="3.07412"
    />
    <path
      d="M34 54.6855L48.3189 69.0045L76.9567 40.3666"
      stroke="white"
      strokeWidth="6.14824"
      strokeLinecap="round"
    />
  </svg>
);

export default HugeCheck;
